import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import vantimage from '@/components/vantImage.vue'
Vue.config.productionTip = false
// main.js
import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'
// import Vconsole from 'vconsole'

  // let vConsole = new Vconsole()
  // Vue.use(vConsole);
// import Vconsole from 'vconsole'
 
// let vConsole = new Vconsole()
Vue.component('vantimage', vantimage);
 
// Vue.use(vConsole);
Vue.use(VueDPlayer);
Vue.use(Vant)
new Vue({
  router,
  store, 
  render: h => h(App)
}).$mount('#app')

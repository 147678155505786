<template>
    <div>
        <van-popup v-model="show" position="bottom" :style="{ height: '7%' }">
            <div style="height:30%;font-size: 5vw;" @click="save">
                <div style="padding-top:4.5%;text-align: center;">
                    保存图片
                </div>
            </div>
        </van-popup>
        <div @touchstart="gtouchstart(1)" @touchmove="gtouchmove()" @touchend="showDeleteButton(1)">
            <van-image :width="width" :height="height" :src="src+houzui" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        src: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '',
        },
        height: {
            type: String,
            default: '',
        },
        houzui:{
            type:String,
            default:'',
        }
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        gtouchstart(item) {
            var self = this;
            this.timeOutEvent = setTimeout(function () {
                self.longPress(item);
            }, 500); //这里设置定时器，定义长按500毫秒触发长按事件
            return false;
        },
        //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
        showDeleteButton(item) {
            clearTimeout(this.timeOutEvent); //清除定时器
            if (this.timeOutEvent != 0) {
                //这里写要执行的内容（如onclick事件）
                console.log("点击但未长按");
            }
            return false;
        },
        //如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
        gtouchmove() {
            clearTimeout(this.timeOutEvent); //清除定时器
            this.timeOutEvent = 0;
        },
        //真正长按后应该执行的内容
        longPress(val) {
            this.timeOutEvent = 0;
            this.show = true;
            //执行长按要执行的内容，如弹出菜单
            console.log("长按");
        },
        save() {
            let that = this
            void plus.gallery.save(this.src, this.$toast('已保存到系统相册'), function (r) {
                // this.$toast('图片保存失败')
                if (r.message == 'No Permission') {
                    that.$toast('保存失败,缺少权限');
                } else {
                    that.$toast('保存失败');
                }
                console.log(r.message, '111');
            });
            this.show = false;
        },
    },
}
</script>